import React from 'react';
import { graphql } from 'gatsby';

import PageLayout from '../components/PageLayout/';
import SEO from '../components/seo';
import { Badges } from '../components/Badges/';
import { Hero } from '../components/Hero/';
import { DownloadDropFriends } from '../styles/newsletter-success';

const NewsletterSuccessPage = ({ data: { Postbote } }) => (
  <PageLayout>
    <SEO title="Newsletteranmeldung erfolgreich" meta={[{ name: 'robots', content: 'noindex' }]} />
    <Hero image={Postbote} title="Du hast dich erfolgreich für den Newsletter registriert.">
      <DownloadDropFriends>Jetzt die DropFriends-App downloaden.</DownloadDropFriends>
      <Badges />
    </Hero>
  </PageLayout>
);

export const query = graphql`
  {
    Postbote: file(relativePath: { eq: "postbote.jpg" }) {
      childImageSharp {
        gatsbyImageData(sizes: "100vw", quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
      }
    }
  }
`;

export default NewsletterSuccessPage;
